/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import usePageContext from 'utils/hooks/usePageContext';
import useTracking from 'components/Globals/Analytics';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';

import { useTranslation } from 'src/i18n';
import { TP, ARTIST_TYPE_ID, BASE_PAGE_ROUTES, ENTITY_TYPE, PROFILE_TYPES } from 'constants/index';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './ConversionFlowBanners.module.scss';

const BannerDetailsDrawer = dynamic(() => import('./BannerDetailsDrawer'));

export const TYPE = {
  CASTING: 'casting',
  ARTIST: 'artist',
};

const trackingData = {
  section: SECTIONS.CON_FLOW_BANNERS,
};

const Banner = ({ type }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');
  const [isOpen, setIsOpen] = useState(false);
  const track = useTracking();
  const activeProfile = useActiveProfileData();
  const { navigate, permissions } = usePageContext();
  const { isLoggedIn } = permissions;
  const isCastingBanner = type === TYPE.CASTING;

  const handleCheckPermission = useCallback(() => {
    if (!isLoggedIn) {
      setIsOpen(true);
      return;
    }

    if (isCastingBanner) {
      if (permissions?.castingToolPermissions?.hasAccess) {
        const linkProps = navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.CASTING,
          onlyLinkProps: true,
        });
        navigate.to(linkProps);
      } else {
        setIsOpen(true);
      }
    } else {
      if (activeProfile?.profileType?.id !== ARTIST_TYPE_ID) {
        setIsOpen(true);
        return;
      }

      if (activeProfile?.profileType?.id === ARTIST_TYPE_ID) {
        const isPro = activeProfile?.subscriptionStatus === PROFILE_TYPES.PRO;

        if (isPro) {
          const linkProps = navigate.getLinkProps({
            entityType: ENTITY_TYPE.ARTIST,
            entity: activeProfile,
            onlyLinkProps: true,
          });
          navigate.to(linkProps);
        } else {
          setIsOpen(true);
        }
      }
    }
  }, [activeProfile, isCastingBanner, isLoggedIn, navigate, permissions?.castingToolPermissions?.hasAccess]);

  const handleOnClick = () => {
    track.click({
      ...trackingData,
      component: isCastingBanner ? COMPONENTS.CASTING_TOOL_BANNER : COMPONENTS.ARTIST_MANAGER_BANNER,
    });

    handleCheckPermission();
  };

  return (
    <>
      <div
        className={classnames(classes.banner, {
          [classes.bannerCasting]: isCastingBanner,
          [classes.bannerArtist]: !isCastingBanner,
        })}
        onClick={handleOnClick}
      >
        <Typography variant="p" weight="bold" className={classes.banner__text}>
          {t(`${TP}.${isCastingBanner ? 'FN_CONVERSION_CASTING' : 'FN_CONVERSION_ARTIST'}`)}
        </Typography>
        <LinkButton
          rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
          size="medium"
          styles={{ root: classes.banner__btn }}
          variant="text"
        >
          {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
        </LinkButton>
      </div>
      {isOpen && <BannerDetailsDrawer isOpen={isOpen} type={type} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const ConversionFlowBanners = ({ isInline = false, showCasting = true, showArtist = true }) => {
  const banners = [];

  if (showCasting) {
    banners.push(<Banner key="casting" type={TYPE.CASTING} />);
  }

  if (showArtist) {
    banners.push(<Banner key="artist" type={TYPE.ARTIST} />);
  }

  if (banners.length === 0) {
    return null;
  }

  if (banners.length === 1) {
    return banners[0];
  }

  return <div className={classnames(classes.cfb, { [classes.cfbInline]: isInline })}>{banners}</div>;
};

export default ConversionFlowBanners;
